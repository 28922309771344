import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Stack, TextField, Tooltip } from '@mui/material';
import useEventListener from '../../hooks/useEventListener';
import { useLocales } from '../../locales';
import Iconify from '../iconify';
export default function RHFCodes({ keyName = '', inputs = [], codeLength = 8, ...other }) {
    const codesRef = useRef(null);
    const { translate } = useLocales();
    const { control, setValue } = useFormContext();
    const handlePaste = (event) => {
        let data = event.clipboardData.getData('text');
        data = data.split('');
        inputs.map((input, index) => setValue(input, data[index]));
        event.preventDefault();
    };
    const handleChangeWithNextField = (event, handleChange) => {
        const { maxLength, value, name } = event.target;
        const fieldIndex = name.replace(keyName, '');
        const fieldIntIndex = Number(fieldIndex);
        const nextfield = document.querySelector(`input[name=${keyName}${fieldIntIndex + 1}]`);
        if (value.length > maxLength) {
            event.target.value = value[0];
        }
        if (value.length >= maxLength && fieldIntIndex < codeLength && nextfield !== null) {
            nextfield.focus();
        }
        handleChange(event);
    };
    useEventListener('paste', handlePaste, codesRef);
    return (_jsxs(Stack, { ref: codesRef, sx: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
        }, children: [_jsx(_Fragment, { children: inputs.map((name, index) => (_jsx(Controller, { name: `${keyName}${index + 1}`, control: control, render: ({ field, fieldState: { error } }) => (_jsx(TextField, { ...field, error: !!error, autoFocus: index === 0, placeholder: "-", onChange: (event) => {
                            handleChangeWithNextField(event, field.onChange);
                        }, onFocus: (event) => event.currentTarget.select(), InputProps: {
                            sx: {
                                width: { xs: 36, sm: 56 },
                                height: { xs: 36, sm: 56 },
                                '& input': { p: 0, textAlign: 'center' },
                            },
                        }, inputProps: {
                            maxLength: 1,
                            type: 'string',
                        }, ...other })) }, name))) }), _jsx(Tooltip, { title: `${translate('Code')}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", sx: {
                        cursor: 'help',
                        color: '#9AA6B2',
                        width: { xs: 180, sm: 180 },
                        height: { xs: 36, sm: 56 },
                    } }) })] }));
}
